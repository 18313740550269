import React, {Component, Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {AiOutlinePauseCircle, AiOutlinePlayCircle} from "react-icons/all";
import axios from "axios";

class HomeRadio extends Component {

    constructor() {
        super();
        this.state={
            PlayIcon:"",
            PauseIcon:"d-none",
            RadioURL:"",
            RadioTitle:"",
            RadioDescription:"",
        }
    }


    componentDidMount() {

        // Play Radio Automatically After 1.5 Second
        setTimeout(()=>{
            if(this.audioInput){
                this.RadioPlay();
            }
        },1500)


        // Get Radio Streaming URL  From Data Directory
        axios.get("data/radio-url.txt").then((res)=>{
            this.setState({RadioURL:res.data})
        })


        // Get Radio Station Title From Data Directory
        axios.get("data/radio-station-title.txt").then((res)=>{
            this.setState({RadioTitle:res.data})
        })


        // Get Radio Station Description From Data Directory
        axios.get("data/radio-station-description.txt").then((res)=>{
            this.setState({RadioDescription:res.data})
        })

    }



    // Play Radio
    RadioPlay= async  ()=>{
        try {
            await this.audioInput.play();
            this.setState({PlayIcon:"d-none",PauseIcon:""})
        }
        catch (error) {
            console.log(error)
        }
    }


    // Pause Radio
    RadioPause=async ()=>{
        try {
            await this.audioInput.pause()
            this.setState({PlayIcon:"",PauseIcon:"d-none"})
        }
        catch (error) {
            console.log(error)
        }
    }



    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col md={8} lg={8}>
                            <audio ref={(audio)=>this.audioInput=audio}  src={this.state.RadioURL}/>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="align-self-center" md={8} lg={8}>
                            <h1 className="radio-point animated slideInDown">
                                <AiOutlinePlayCircle onClick={this.RadioPlay} className={this.state.PlayIcon+" radio-play-pause"}/>
                                <AiOutlinePauseCircle onClick={this.RadioPause} className={this.state.PauseIcon+" radio-play-pause"}/>


                                {/* NUMERO DA RADIO */}
                                En vivo
                                {/* NUMERO DA RADIO */}


                                
                            </h1>
                            <h1 className="radio-name ml-3 animated slideInUp">{this.state.RadioTitle}</h1>
                            <p className="radio-des ml-3 animated slideInUp">{this.state.RadioDescription}</p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default HomeRadio;